<template>
    <div v-if="!loading">
        <div v-if="agency.managers_count" class="row">
            <div class="col-md-12 col-lg-4 col-xl-2">
                <Balance :amount="agency.managers_count" icon="fas fa-users" subtitle="Managers" title="" />
            </div>
            <div class="col-md-12 col-lg-4 col-xl-2">
                <Balance :amount="agency.consultants_count" icon="fas fa-user" subtitle="Consultants" title="" />
            </div>

            <div class="col-md-12 col-lg-auto ml-auto">
                <a
                    href="https://www.nextlevel.link/decouvrez-le-nouveau-mode-agence-de-nextlevel.html"
                    class="btn bg-warning shadow"
                    target="_blank"
                >
                    <i class="fas fa-info-circle m-2" />Mode d'emploi
                </a>
            </div>
        </div>

        <b-tabs size="is-medium" class="block">
            <b-tab-item :label="`Agence (${agency.managers_count + agency.consultants_count})`" icon="building">
                <div class="mb-3">
                    <CreateEmployeeButton @reload="reload" />
                </div>

                <div v-show="agency.managers_count" class="mb-2">
                    <ManagersTable ref="managers" @reload="reload" />
                </div>

                <div v-show="agency.consultantsWithoutManager" class="box">
                    <h2>Consultants sans manager</h2>
                    <ConsultantsTable ref="consultants" @reload="reload" />
                </div>
            </b-tab-item>
            <b-tab-item :label="`Clients (${agency.agency_clients_count})`" icon="address-book">
                <div class="mb-3">
                    <CreateAgencyClientButton @reload="$refs.clients.reloadClients()" />
                </div>

                <AgencyClientsTable v-if="agency.agency_clients_count" ref="clients" />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import CreateEmployeeButton from '@/components/Agency/Button/CreateEmployeeButton.vue'
    import CreateAgencyClientButton from '@/components/Agency/Button/CreateAgencyClientButton.vue'

    import ManagersTable from '@/components/Agency/Table/ManagersTable'
    import ConsultantsTable from '@/components/Agency/Table/ConsultantsTable'
    import AgencyClientsTable from '@/components/Agency/Table/AgencyClientsTable'

    import { mapActions, mapState } from 'vuex'

    export default {
        title: 'Agence',
        components: {
            ConsultantsTable,
            ManagersTable,
            AgencyClientsTable,
            CreateEmployeeButton,
            CreateAgencyClientButton
        },
        data() {
            return {
                loading: true
            }
        },
        created() {
            let config = this.$store.state.consultants.consultantsConfig
            config.filters.without_manager = true

            this.$store.commit('consultants/setConsultantsConfig', config)
            this.$store.commit('consultants/setScope', 'agency')
        },
        computed: {
            ...mapState('consultants', ['agency'])
        },
        methods: {
            ...mapActions('consultants', {
                getAgency: 'getAgency'
            }),
            fetchAgency() {
                this.loading = true
                this.getAgency()
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            reload() {
                if (this.agency.managers_count && this.agency.consultantsWithoutManager) {
                    this.$refs.managers.fetchData()
                    this.$refs.consultants.fetchData()
                } else {
                    this.loading = true
                    this.getAgency()
                        .then(() => {
                            if (this.$refs.managers) {
                                this.$refs.managers.fetchData()
                            }

                            if (this.$refs.consultants) {
                                this.$refs.consultants.fetchData()
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            }
        },
        mounted() {
            this.fetchAgency()
        }
    }
</script>
